import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse  } from '@angular/common/http';
import { faGears, faGear, faCubes, faChartPie, faSkullCrossbones, faHeartPulse, faCalendarDays, faClipboardList, faTriangleExclamation, faCircleQuestion, faClock } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck, faEnvelope }  from '@fortawesome/free-regular-svg-icons';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AuthenticateService } from '../services/token.service';
import { StatusService } from '../services/Status.service';
import { AccessToken } from '../models/AccessToken';
import { Status } from 'src/models/Status';
declare var bootstrap: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'paqs-monitor';
  environment = environment.env;
  version = environment.version;
  timeStamp = moment().format('L h:mm:ss a');
  displayInitSpinner: boolean = true;
  displayDashboard: boolean = false;
  displayError: boolean = false;
  errorMessage: string = '';
  
  // font-awesome icons
  errorStatusIcon = faSkullCrossbones;
  errorStatusClass = "text-danger";
  errorStatusRowColor = "table-danger";
  goodStatusIcon = faCircleCheck;
  goodStatusClass = "text-success";
  goodStatusRowColor = "table-success";
  warningIcon = faTriangleExclamation;
  warningStatusClass = "text-warning";
  warningStatusRowColor = "table-warning";
  unknownStatusIcon = faCircleQuestion;
  unknownStatusClass = "text-secondary";
  unknownStatusRowColor = "table-secondary";

  heartBeatIcon = faHeartPulse;
  servicesIcon = faGears;
  serviceIcon = faGear;
  faCubes = faCubes;
  faChartPie = faChartPie;
  updateDateIcon = faCalendarDays;
  messagesIcon = faEnvelope;
  statusIcon = faClipboardList;
  processAgeIcon = faClock;

  serviceStatusList: Status[] = new Array();
  batchStatusList: Status[] = new Array();

  constructor(private authService: AuthenticateService, private statusService: StatusService) { }

  ngOnInit(): void {
    
    this.authService.getAndResetToken().subscribe(  
      (data : AccessToken) => {
          if (data.access_token) {
            this.authService.setAccessTokenData(data);
            this.fetchStatusData();
          } 
      },
      (error: HttpErrorResponse) => {
        this.errorHandler(`Error obtaining authorization token! Error: ${error.status}`);
      }
    );
  }

  private fetchStatusData(): void {
    this.statusService.getServiceStatusList().subscribe(  
      (statusList : Status[]) => {
          if (statusList.length > 0) {
            this.displayInitSpinner = false;
            this.displayDashboard = true;
            this.processStatuses(statusList);
          } 
      },
      (error: HttpErrorResponse) => {
        this.errorHandler(`Error fetching service processing status! Error: ${error.status}`);
      }
    );
  }

  private processStatuses(statusList: any[]) {
    statusList.forEach((serviceStatus) => {
      let status = new Status(serviceStatus.name, serviceStatus.processType, serviceStatus.status, serviceStatus.statusDate, serviceStatus.eventCount);

      status.statusAge = moment(status.statusDate).toNow(true);
      status.statusDate = moment(status.statusDate).format("M/D/YYYY HH:mm:ss");

      switch (status.status) {
        case "OK":
          status.statusIcon = this.goodStatusIcon;
          status.statusIconColor = this.goodStatusClass;
          status.statusRowColor = this.goodStatusRowColor;
          break;
        case "Error":
          status.statusIcon = this.errorStatusIcon;
          status.statusIconColor = this.errorStatusClass;
          status.statusRowColor = this.errorStatusRowColor;
          break;
        case "Warn":
          status.statusIcon = this.warningIcon;
          status.statusIconColor = this.warningStatusClass;
          status.statusRowColor = this.warningStatusRowColor;
          break;      
        default:
          status.statusIcon = this.unknownStatusIcon;
          status.statusIconColor = this.unknownStatusClass;
          status.statusRowColor = this.unknownStatusRowColor;
          status.status = "Unknown";
          break;
      }
      
      if (status.processType === "Service"){
        this.serviceStatusList.push(status);
      }

      if (status.processType === "Batch"){
        this.batchStatusList.push(status);
      }
    });

  };

  private errorHandler = (errMsg: string) => {
    console.log(`Error: ${errMsg}`);
    this.displayInitSpinner = false;
    this.displayError = true;
    this.errorMessage = errMsg;
  }

}
