import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

import { AuthenticateService } from './token.service';
import { Status } from 'src/models/Status';


@Injectable({
  providedIn: 'root'
})
export class StatusService {
  url(): string { return environment.apiEndpoint + environment.statusService; }

  constructor(protected http: HttpClient, private authService: AuthenticateService) { }

    getServiceStatusList(): Observable<Status[]> {

      const accessToken = this.authService.getToken();

      const headerDict = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*'
      }

      const requestOptions = { headers: new HttpHeaders(headerDict)};

      return this.http.get<any>(this.url(), requestOptions);
    }
}
