import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { AccessToken } from '../models/AccessToken';

@Injectable()
export class AuthenticateService {

  url(): string { return `${environment.apiEndpoint}${environment.authenticationService}`; }

  constructor(
    private http: HttpClient, 
    private router: Router
    ) {}

  getNewToken(){     
    const headerDict = {
      'Authorization': `Basic ${environment.authToken}`,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    }     

    const requestOptions = { headers: new HttpHeaders(headerDict) };
    const requestBody = new HttpParams().set('grant_type', 'client_credentials');

    return this.http.post<AccessToken>(this.url(), requestBody.toString(), requestOptions);
  }

  getAndResetToken(){
    this.clearSession();
    return this.getNewToken();
  }

  getToken(){
    let jwtExp = localStorage.getItem('jwtExp');
    if ((jwtExp !== undefined) && (jwtExp !== null)){
      let jwtHasValidExpiration = moment().isBefore(moment.unix(parseInt(jwtExp)), 'minutes');
      if (jwtHasValidExpiration){
        return localStorage.getItem('jwtToken');
      } else {
        this.getAndResetToken();
      }
    } else {
      this.getAndResetToken();
    }

    return localStorage.getItem('jwtToken') !== undefined ? localStorage.getItem('jwtToken') :  "";
  }

  get isAuthenticated(): boolean {
    // Check token exists and that it hasn't expired
    let token: string|null = localStorage.getItem('jwtToken');
    let jwtExp: string|null = localStorage.getItem('jwtExp');
    if( !token || !jwtExp) return false;

    let tokenIsValid: boolean = false;

    if (jwtExp){
      let tokenExpire = moment.unix(+jwtExp);
      tokenIsValid =  moment().isBefore(tokenExpire);
    }
    
    return tokenIsValid;   
  }

  checkSessionToken(): boolean {
    let jwtExp: string|null = localStorage.getItem('jwtExp');
    let isExpired: boolean = false;

    if (jwtExp){
      let tokenExpire = moment.unix(+jwtExp);
      isExpired =  moment().isAfter(tokenExpire);
    } else {
      isExpired = true;
    }

    return isExpired;
  }

  setAccessTokenData(authResult: AccessToken): boolean {
    localStorage.setItem('jwtToken', authResult.access_token);
    localStorage.setItem('jwtExp', moment().add(authResult.expires_in, 's').unix().toString());

    return this.isAuthenticated;
  }

  setAuthenticationData(authToken: string, tokenExp: string): boolean {
    localStorage.setItem('jwtToken', authToken);
    localStorage.setItem('jwtExp', tokenExp);

    return this.isAuthenticated;
  }

  clearSession(){
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtExp');
  }
}

