import { faSkullCrossbones, IconDefinition } from '@fortawesome/free-solid-svg-icons';
export class Status {
    name: string;
    processType: string;
    status: string;
    statusDate: string;
    statusAge: string = "";
    eventCount: number; 
    statusIcon: IconDefinition = faSkullCrossbones;
    statusIconColor: string = "";
    statusRowColor: string = "";

    constructor(name: string, processType: string, status: string, statusDate: string, eventCount: number){
        this.name = name;
        this.processType = processType;
        this.status = status;
        this.statusDate = statusDate;
        this.eventCount = eventCount;
    }
}