<div class="content" role="main">

  <div class="px-2 py-2 my-2 text-center">
    <div class="row">
      <div class="col-3">
        <div class="alert alert-primary text-center" role="alert">
          <h4 class="alert-heading">PAQS Monitor {{environment}} <fa-icon [icon]="faChartPie" class="text-primary"></fa-icon>
          </h4>
          <span><small>{{timeStamp}}</small></span>
          <hr>
          <p class="mb-0">PAQS System Services &amp; Batch Processing<br /> Status and Metrics</p>
          <span class="text-muted position-absolute bottom-0 start-0"><small>Ver: {{version}}</small></span>
        </div>
      </div>
      <div class="col-8 border">
        <div id="dashboard-body" *ngIf="displayDashboard">
          <h1 class="display-4">
            <fa-icon [icon]="servicesIcon" class="text-muted"></fa-icon> Service Status
          </h1>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Status <fa-icon [icon]="statusIcon" class="text-dark" size="xs"></fa-icon></th>
                <th scope="col">Service Name <fa-icon [icon]="serviceIcon" class="text-dark" size="xs"></fa-icon></th>
                <th scope="col">Last Update <fa-icon [icon]="updateDateIcon" class="text-dark" size="xs"></fa-icon></th>
                <th scope="col">Heartbeat Age <fa-icon [icon]="heartBeatIcon" class="text-dark" size="xs"></fa-icon></th>
                <th scope="col">Events Processed <fa-icon [icon]="messagesIcon" class="text-dark" size="xs"></fa-icon></th>
              </tr>
            </thead>
            <tbody *ngFor="let service of serviceStatusList">
              <tr [class]="service.statusRowColor">
                <td data-bs-toggle="tooltip" data-bs-placement="right" title="{{service.status}}" ><fa-icon [icon]="service.statusIcon" [class]="service.statusIconColor" size="lg"></fa-icon></td>
                <td>{{service.name}}</td>
                <td>{{service.statusDate}}</td>
                <td>{{service.statusAge}}</td>
                <td>{{service.eventCount}}</td>
              </tr>
            </tbody>
          </table>

        <h1 class="display-4 py-2 my-2">
          <fa-icon [icon]="faCubes" class="text-muted"></fa-icon> Batch Processing Status
        </h1>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Status <fa-icon [icon]="statusIcon" class="text-dark" size="xs"></fa-icon>
              </th>
              <th scope="col">Process Name <fa-icon [icon]="serviceIcon" class="text-dark" size="xs"></fa-icon>
              </th>
              <th scope="col">Last Processed <fa-icon [icon]="updateDateIcon" class="text-dark" size="xs"></fa-icon>
              </th>
              <th scope="col">Processing Age <fa-icon [icon]="processAgeIcon" class="text-dark" size="xs"></fa-icon></th>
              <th scope="col">Cases Processed <fa-icon [icon]="messagesIcon" class="text-dark" size="xs"></fa-icon>
              </th>
            </tr>
          </thead>
          <tbody *ngFor="let process of batchStatusList">
            <tr [class]="process.statusRowColor">
              <td>
                <fa-icon [icon]="process.statusIcon" [class]="process.statusIconColor" size="lg" title="{{process.status}}"></fa-icon>
              </td>
              <td>{{process.name}}</td>
              <td>{{process.statusDate}}</td>
              <td>{{process.statusAge}}</td>
              <td>{{process.eventCount}}</td>
            </tr>
          </tbody>
        </table>

        </div>

        <div id="init-body" class="px-4 py-5 my-5 text-center" *ngIf="displayInitSpinner">
          <img src='/assets/images/loading-icon.gif' alt="initializing..." width="100" height="100" />
          <h4>initializing...</h4>
        </div>

        <div id="app-error" class="text-center" *ngIf="displayError">
          <div class="px-4 py-5 my-5 text-center">
            <img src='/assets/images/sorry-icon.png' alt="oops..." width="100" height="100">
            <h1 class="display-5 fw-bold">Oops!</h1>
            <div class="col-lg-6 mx-auto">
              <p class="lead mb-4">{{errorMessage}}</p>
            </div>
          </div>
        </div>

      </div>
      <div class="col-1">
        &nbsp;
      </div>  
    </div>    
  </div>

</div>